import * as React from 'react'
import * as Styles from './insurerReview.module.scss'
import GetStars from '../getStars/getStars'

const Layout = ({ data }) => {
	let reviewImages = data.reviewImages;
	let niceImages = [];
	Object.keys(data.reviewImages).forEach(function(key, index) {
		niceImages.push(data.reviewImages[key].guid);
	})

	let date = new Date(data.date);
	let options = {year: 'numeric', month: 'long', day: 'numeric' };
	let dateFormatted = date.toLocaleDateString("en-US", options);
	let result = data.reviewLink.replace("https://dev-petted2.pantheonsite.io/", "https://www.petted.com/");
	const closeModal = (elem) => {
		if (typeof window !== 'undefined') {
			let eles = document.querySelectorAll(".modalActive");
			document.querySelector('body').classList.remove('hasModal');
			eles.forEach(ele => {
				ele.classList.remove('modalActive');
			});
		}
	}

	const openModal = (elem) => {
		if (typeof window !== 'undefined') {
			let image = elem.target.dataset.src;
			document.getElementById('modalImage').src = image;
			document.getElementById('imagePopup').classList.add('modalActive');
			document.getElementById('imagePopup').parentElement.classList.add('modalActive');
			document.querySelector('body').classList.add('hasModal');
		}
	}

	return (
		<div className="review">
			<GetStars section={data.rating}/>
			{
				(data?.title) ?
					<div className={Styles.reviewTitle}>
						<h3>{data.title}</h3>
					</div> 
				: '' 
			}
			<div className={Styles.reviewOverview}>
				My {(data?.custom.pet_age) ? data.custom.pet_age + '-year-old' : ''} {(data?.getBreed[0]?.name) ? data.getBreed[0].name : ''} in {(data?.custom?.state) ? data.custom.state : ''} was insured by <a href={result}>{data.reviewText}</a>
			</div>
			{
				(data?.content) ?
					<div className={Styles.reviewContent}>
						<p>{data.content}</p>
					</div>
				: '' 
			}
			{
				(data?.name) ?
					<div className={Styles.reviewAuthor}>
						<p>{data.name}</p>
					</div>
				: '' 
			}
			{
				(dateFormatted) ?
					<div className={Styles.reviewDate}>
						<p>{dateFormatted}</p>
					</div>
				: '' 
			}
			{
				(data?.reviewImages) ?
					<div className={Styles.reviewImagesWrap}>
						<div className={Styles.reviewImages}>
							{
								niceImages.map((image, index) => {
									return (
										<a href="javascript:void(0);" onClick={openModal} data-src={niceImages[index]} key={index}>
											<img loading='lazy' src={niceImages[index]}/>
										</a>
									)
								})
							}
						</div>
						<div className={Styles.modalOverlay} onClick={closeModal}>
							<div
							role="dialog"
							id="imagePopup"
							aria-labelledby={"imagePopup_title"}
							aria-modal="true"
							className={Styles.modal}
							data-open-page-load="false"
							>
								<div className={Styles.modalHeader}>
									<button className={Styles.modalClose} aria-label="Close Modal" onClick={closeModal}>
									<svg fill="none" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M6 18L18 6M6 6l12 12"></path></svg>
									</button>
								</div>
								<div className={Styles.modalContent}>
									<img id="modalImage" src="" alt="Modal Image" />
								</div>
							</div>
						</div>
					</div>
				: ''
			}
			
			{
				(data.response) ? 
					<div className={Styles.reviewResponse}>
						<div className={Styles.reviewResponseInner}>
							<p><strong>Response from pettedadmin</strong></p><p>hahaha no way</p>
						</div>
					</div>
				: ''
			}
		</div>
	)
}

export default Layout