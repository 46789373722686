// extracted by mini-css-extract-plugin
export var active = "insurerReview-module--active--efdd9";
export var hasModal = "insurerReview-module--hasModal--65cfa";
export var modal = "insurerReview-module--modal--11cf7";
export var modalActive = "insurerReview-module--modalActive--542c1";
export var modalClose = "insurerReview-module--modalClose--74bba";
export var modalContent = "insurerReview-module--modalContent--bf444";
export var modalHeader = "insurerReview-module--modalHeader--55d8e";
export var modalOverlay = "insurerReview-module--modalOverlay--cf3b2";
export var reviewAuthor = "insurerReview-module--reviewAuthor--81c4d";
export var reviewContent = "insurerReview-module--reviewContent--e98ff";
export var reviewDate = "insurerReview-module--reviewDate--b1050";
export var reviewImages = "insurerReview-module--reviewImages--bae7a";
export var reviewOverview = "insurerReview-module--reviewOverview--24cd0";
export var reviewResponse = "insurerReview-module--reviewResponse--ae660";
export var reviewTitle = "insurerReview-module--reviewTitle--da896";